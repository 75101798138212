<template>
  <ul class="pa-0">
    <li class="item">
      <v-icon class="mr-2" :color="$helpers.getValidationColor($helpers.minLength(password))">
        {{ $helpers.getValidationIcon($helpers.minLength(password)) }}
      </v-icon>
      {{ $t("minLength") }}
    </li>
    <li class="item mt-2">
      <v-icon
        class="mr-2"
        :color="
          $helpers.getValidationColor(
            $helpers.hasLowerCase(password) && $helpers.hasUpperCase(password) && $helpers.hasDigit(password)
          )
        "
      >
        {{
          $helpers.getValidationIcon(
            $helpers.hasLowerCase(password) && $helpers.hasUpperCase(password) && $helpers.hasDigit(password)
          )
        }}
      </v-icon>
      {{ $t("hasLowerCaseAndUpperCase") }}
    </li>
    <li class="item mt-2">
      <v-icon class="mr-2" :color="$helpers.getValidationColor($helpers.hasSpecialCharacter(password))">
        {{ $helpers.getValidationIcon($helpers.hasSpecialCharacter(password)) }}
      </v-icon>
      {{ $t("hasSpecialCharacter") }}
    </li>
  </ul>
</template>
<script>
  export default {
    name: "PasswordValidationMessages",
    props: { password: String }
  };
</script>
<style scoped>
  .item {
    display: flex;
    align-items: center;
  }
</style>
